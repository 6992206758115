import React from "react"
import { Helmet } from "react-helmet"
import "../styles/simulators.scss"
export default function Template({ data, pageContext }) {
  const flag = () => typeof window !== "undefined"

  return (
    <>
      <section className="simulators-container webgl-content">
        <div className="simulator">
          <Helmet title={pageContext.name}></Helmet>
          <iframe
            frameBorder="0"
            src={pageContext.url}
            allowFullScreen
            width="100%"
            height="100%"
            id="unityContainer2"
          ></iframe>
        </div>
      </section>
    </>
  )
}
